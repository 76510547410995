<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách tài khoản Black list</h6>
        </template>
        <b-row>
            <b-col cols="4">
                <b-form-group label="Nhập số điện thoại/số thẻ/tài khoản">
                    <b-form-input
                            v-model="params.value"
                            placeholder="Nhập số ..."
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col class="text-left align-self-center">
                <b-button
                        v-if="this.resetButton"
                        class="mr-2"
                        variant="outline-primary"
                        @click="reload()"
                >Tải lại
                </b-button>
                <b-button
                        class="mr-2"
                        variant="outline-primary"
                        @click="search()"
                >Tìm kiếm
                </b-button>
                <b-button
                        class="mr-2"
                        variant="outline-primary"
                        @click="createForm()"
                >Thêm mới </b-button
                >&nbsp;
            </b-col>
        </b-row>
        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(is_active)="data">
            <strong
              :style="getColorStatus(data.value, 'WHITE_LIST')"
            >{{ data.value }}</strong>
          </template>
          <template #cell(options)="data">
            <button @click="remove(data.item.id)" variant="outline-primary">
              <i class="fa fas fa-trash" style="color: #db3d3d"></i></button
            >
          </template>
        </b-table>
          <div v-if="items.length === 0" class="no-data-message" style="padding: 10px;text-align: center;margin: 20px auto;max-width: 400px;">
              Không tìm thấy thông tin
          </div>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import BankConfig from "@/core/config/bank.config";

const BlackListRepository = RepositoryFactory.get("blackList");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      params: {
        service: null,
        bankCode: null,
        type: null,
        phone: null,
        card_no: null,
        value: null,
      },
      option: {
        service: [
          { value: "TRANSFER_BANK", text: "Rút tiền qua chuyển khoản" },
          { value: "WITH_DRAW", text: "Rút tiền qua liên kết trực tiếp" },
        ],
        bank: [],
        type: [
          { value: "CARD", text: "Số thẻ" },
          { value: "ACCOUNT", text: "Số tài khoản" },
        ],
      },
      fields: [
        { id: "ID" },
        { service: "Dịch vụ" },
        { bank_code: "Ngân hàng" },
        { type: "Loại" },
        { value: "Số thẻ / tài khoản" },
        { reason: "Lý do" },
        { created_by: "Người tạo" },
        { is_active: "Trạng thái" },
        { created_at: "Ngày tạo" },
        { options: "Tùy chọn" },
      ],
      url: this.$route,
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      resetButton: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tài khoản", route: "account" },
      { title: "Tài khoản Merchant", route: "merchant" },
    ]);
  },
  methods: {
    remove(id) {
      if (confirm("Xác nhận xóa khỏi black list?")) {
        let params = {
          id: id,
        };
        const self = this;
        this.$bus.$emit("show-loading", true);
        BlackListRepository.remove(params)
          .then((response) => {
            if (response.data.error_code == 0) {
              this.notifyAlert("success", 'Xóa tài khoản khỏi black list thành công');
              self.listAll();
              this.$bus.$emit("show-loading", false);
            } else {
              this.notifyAlert("warn", response.data.message);
              this.$bus.$emit("show-loading", false);
            }
          })
          .catch(() => {
            this.notifyAlert("warn", "Có lỗi xảy ra");
            this.$bus.$emit("show-loading", false);
          });
      }
    },
    async getList(params) {
      await BlackListRepository.getList(params)
        .then((response) => {
            if (response.data.error_code) {
                Vue.notify({
                    group: "foo",
                    type: "warn",
                    title: response.data.message,
                });
            } else {
                let result = response.data.data.list.data;
                let res = [];
                let self = this;
                result.forEach(function (item) {
                    let resData = self.rebuildResponse(item);
                    res.push(resData);
                });
                this.items = res;
                this.paginate.total = response.data.data.total;
                this.paginate.totalPage = response.data.data.last_page;
                this.paginate.currentPage = response.data.data.current_page;
            }
        })
        .catch(() => {
            alert("Có lỗi xảy ra");
        });
    },
    listAll() {
      this.$bus.$emit("show-loading", true);
      let numberPage = 1;
      if (typeof this.$route.query.page != "undefined") {
        numberPage = this.$route.query.page;
      }
      let params = {
        page: numberPage,
      };
      this.getList(params);
      this.$bus.$emit("show-loading", false);
    },
    rebuildResponse(item) {
      if (item.type === 'CARD' || item.type === 'ACCOUNT') {
        let service = item.service.substring(0, 13);
        let bankCode = item.service.substring(14);
        item.service = service;
        item.bank_code = bankCode;
      } else if (item.type === 'DIRECT_LINK') {
        let service = item.service.substring(0, 9);
        let bankCode = item.service.substring(10);
        item.service = service;
        item.bank_code = bankCode;
      }
      if (item.type === 'ACCOUNT') {
        item.type = 'Tài khoản';
      } else if (item.type === 'CARD') {
        item.type = 'Thẻ';
      } else if (item.type === 'DIRECT_LINK') {
        item.type = 'Liên kết trực tiếp';
      }
      if (item.is_active == 1) {
        item.is_active = 'Kích hoạt';
      } else {
        item.is_active = 'Đã hủy';
      }
      if (item.service === 'TRANSFER_BANK') {
        item.service = 'Rút tiền qua chuyển khoản';
      } else if (item.service === 'WITH_DRAW') {
        item.service = 'Rút tiền qua liên kết';
      }
      return item;
    },
    reload() {
      this.$bus.$emit("show-loading", true);
      this.getList({page: 1});
      this.$bus.$emit("show-loading", false);
      this.resetButton = false;
      this.params.value = null;
    },
    search() {
      if (this.params.value === null) {
        this.notifyAlert("warn", "Vui lòng nhập thông tin tìm kiếm");
        return;
      }
      let params = {
        'value':this.params.value,
      };
      this.$bus.$emit("show-loading", true);
      this.getList(params);
      this.$bus.$emit("show-loading", false);
      this.resetButton = true;
    },
    createForm() {
      this.$router.push("/account/black-list/add");
    },
    fillBankListTransfer($bankType) {
      var listBank = [];
      if ($bankType === 'TRANSFER_BANK') {
        listBank = BankConfig.getBankTransfer();
      } else if ($bankType === 'WITH_DRAW') {
        listBank = BankConfig.getBankLink();
      }
      let bank = [];
      listBank.forEach(function (item) {
        bank.push({value: item, text: item})
      });
      this.option.bank = bank;
    }
  },
  created() {
    this.listAll();
  },
  computed: {},
  watch: {
    currentPage() {
      this.listAll();
    },
    'params.service': function (newVal) {
      if (newVal === 'TRANSFER_BANK') {
        this.fillBankListTransfer(newVal);
      } else if (newVal === 'WITH_DRAW') {
        this.fillBankListTransfer(newVal);
      } else {
        this.option.bank = [];
      }
    }
  },
};
</script>